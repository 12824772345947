import Bugsnag from "@bugsnag/js";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import intl from "../../services/intl";
import { fetchDataAfterAuth } from "../../store/app/actions";
import { authenticate } from "../../utils/authenticate";
import fetchInternal from "../../utils/fetch";
import { Font, responsive } from "../../utils/style";

const Section = styled.section`
  display: flex;
  padding: var(--between-components-modules-spacing-2, 32px)
    var(--between-components-modules-spacing-15, 24px);
  margin-bottom: var(--between-components-modules-spacing-2, 32px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: var(--between-components-only-spacing-025, 4px);
  border: 1px solid var(--solid-primary-indigo-blue-100, #142b6f);
  gap: var(--between-components-modules-spacing-15, 24px);

  ${responsive.md`
    padding: var(--between-components-modules-spacing-3, 48px);
    gap: var(--between-components-modules-spacing-2, 32px);
  `}

  // Fade out after password is set
  // transition: opacity 200ms, height 500ms, padding 500ms, margin 500ms,
  //   border 200ms, visibility 500ms;

  &.hide {
    display: none;

    // For a fade out
    // opacity: 0;
    // height: 0;
    // padding: 0;
    // margin: 0;
    // border: 0;
    // visibility: hidden;
    // overflow: hidden;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;

    input[type="password"] {
      border-radius: var(--between-components-only-spacing-025, 4px);
      border: 1px solid var(--solid-primary-indigo-blue-60, #62719f);
      background: var(--solid-neutral-white, #fff);
      height: 59px;
      padding: var(--between-components-only-spacing-05, 8px)
        var(--between-components-modules-spacing-1, 16px);
      align-self: stretch;

      color: var(--solid-primary-indigo-blue-100, #142b6f);
      ${Font.circular};
      font-size: 16px;
      font-style: normal;
      font-weight: 450;
      line-height: 24px; /* 150% */

      &:focus {
        border-color: var(--solid-primary-indigo-blue-100, #142b6f);
        outline: none;
      }

      &.error {
        border: 2px solid var(--solid-semantic-error-red, #c83d1e);
      }
    }

    .error-message {
      color: var(--solid-semantic-error-red, #c83d1e);
      ${Font.circular};
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 150% */
      margin: 8px 0 0;
    }

    button {
      padding: 12px 24px;
      border-radius: 25px;
      border: 0px;
      text-align: center;
      ${Font.circular};
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      background: var(--solid-primary-indigo-blue-100, #142b6f);
      color: var(--solid-neutral-white, #fff);
      margin-top: var(--between-components-modules-spacing-15, 24px);

      &:hover {
        background: var(--solid-primary-yellow-100, #ffd600);
        color: var(--solid-primary-indigo-blue-100, #142b6f);
      }

      &:focus {
        color: var(--solid-neutral-white, var(--solid-neutral-white, #fff));
        background: var(--solid-primary-indigo-blue-100, #142b6f);
      }

      &:disabled {
        background: var(--solid-neutral-cool-40, #eaeef0);
        color: var(--solid-primary-indigo-blue-40, #a1aac5);
      }
    }
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: var(--between-components-only-spacing-075, 12px);
  align-self: stretch;

  h2 {
    color: #142b6f;
    text-align: center !important;
    ${Font.circular};
    font-style: normal;
    font-weight: 400;

    font-size: 20px;
    line-height: 25px; /* 125% */

    ${responsive.md`
      font-size: 24px;
      line-height: 30px; /* 125% */
      letter-spacing: -0.24px;
    `}
  }

  p {
    color: var(--solid-primary-indigo-blue-100, #142b6f);
    text-align: center;

    ${Font.dutch};
    font-style: normal;
    font-weight: 400;

    font-size: 14px;
    line-height: 20px; /* 143% */

    ${responsive.md`
      font-size: 16px;
      line-height: 24px; /* 150% */
    `}
  }
`;

const SuccessMessage = styled.div`
  color: #142b6f;
  text-align: center;
  ${Font.circular};
  font-style: normal;
  font-weight: 300;

  font-size: 20px;
  line-height: 25px; /* 125% */

  ${responsive.md`
    font-size: 24px;
    line-height: 30px; /* 125% */
    letter-spacing: -0.24px;
  `}

  p {
    margin: 0;
  }
`;

const SetPassword = ({ email, token }) => {
  const [password, setPassword] = useState("");
  const [isPasswordSet, setIsPasswordSet] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [hideComponent, setHideComponent] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setIsPasswordValid(e.target.value.length >= 6);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isPasswordValid) return;

    fetchInternal("users/reset_password", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      fetchOptions: {
        addCastleRequestToken: true,
      },
      body: JSON.stringify({ token, password }),
    })
      .then(() => {
        sessionStorage.removeItem("rit-reset_password_token");
        setIsPasswordSet(true);
        setShowSuccessMessage(true);
        const timeoutId = setTimeout(() => setHideComponent(true), 5000);
        setTimeoutId(timeoutId);

        authenticate(email, password)
          .then(() => {
            dispatch(fetchDataAfterAuth);
          })
          .catch((error) => {
            Bugsnag.notify(error);
          });
      })
      .catch((error) => {
        setErrorMessage("An error occurred. Please try again.");
        Bugsnag.notify(
          new Error(`Error resetting password for email ${email}`),
        );
      });
  };

  return (
    <Section className={hideComponent ? "hide" : ""}>
      {!isPasswordSet && (
        <Header>
          <h2>{intl.t("checkout.confirmation.set-password.title")}</h2>
          <p>{intl.t("checkout.confirmation.set-password.copy")}</p>
        </Header>
      )}
      {!isPasswordSet && (
        <form onSubmit={handleSubmit}>
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={handlePasswordChange}
            maxLength={40}
            minLength={6}
            autoComplete="new-password"
            className={errorMessage ? "error" : ""}
          />
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <button type="submit" disabled={!isPasswordValid}>
            {intl.t("checkout.confirmation.set-password.button")}
          </button>
        </form>
      )}
      {isPasswordSet && showSuccessMessage && (
        <SuccessMessage>
          <p>{intl.t("checkout.confirmation.set-password.success")}</p>
        </SuccessMessage>
      )}
    </Section>
  );
};

export default SetPassword;
