import styled from "styled-components";

import { Font, responsive } from "../../utils/style";
import intl from "../../services/intl";

import highFive from "../../../static/svg/icon-high-five.svg";

const ReferralSection = styled.section`
  display: flex;
  padding: var(--between-components-modules-spacing-3, 48px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--between-components-modules-spacing-15, 24px);
  flex-shrink: 0;

  border-radius: var(--between-components-only-spacing-025, 4px);
  background: var(--solid-secondary-sky-blue-20, #eaf1f5);

  h2 {
    color: #142b6f;
    text-align: center;
    ${Font.circular};
    font-style: normal;
    font-weight: 400;

    font-size: 20px;
    line-height: 25px; /* 125% */

    ${responsive.md`
      font-size: 24px;
      line-height: 30px; /* 125% */
      letter-spacing: -0.24px;
    `}
  }

  p {
    color: var(--solid-primary-indigo-blue-100, #142b6f);
    text-align: center;

    ${Font.dutch};
    font-style: normal;
    font-weight: 400;

    font-size: 14px;
    line-height: 20px; /* 143% */

    ${responsive.md`
      font-size: 16px;
      line-height: 24px; /* 150% */
    `}
  }
`;

const Referral = () => {
  // Do not show Referral section for UK
  const locale = intl.locale;
  if (locale === "en-GB" || locale === "en-CA") {
    return null;
  }

  if (typeof window._talkableq === "undefined") {
    return null;
  }

  return (
    <ReferralSection>
      <img src={highFive} alt="Two hands doing a high five" />
      <h2>{intl.t("checkout.confirmation.referral.title")}</h2>
      <p>{intl.t("checkout.confirmation.referral.copy")}</p>
      <div id="talkable-offer"></div>
    </ReferralSection>
  );
};

export default Referral;
