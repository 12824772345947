import styled from "styled-components";
import intl from "../../services/intl";
import mapboxgl from "mapboxgl";
import "mapbox-gl/dist/mapbox-gl.css";

import "react-phone-number-input/style.css";
import Input from "react-phone-number-input/input";
import { isValidPhoneNumber } from "react-phone-number-input";

import { useEffect, useState, useRef } from "react";
import { getStoredUser } from "../../utils/currentUser";

import { Font, Color, responsive } from "../../utils/style";
import { Icons } from "../../utils/react-svg";
import metrics from "../../utils/metrics";

const OrderTrackingSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  border-radius: var(--between-components-only-spacing-025, 4px);
  border: 1px solid var(--solid-neutral-cool-100, #c3cfd5);

  .map {
    height: 280px;
    overflow: hidden;
    border-radius: var(--between-components-only-spacing-025, 4px)
      var(--between-components-only-spacing-025, 4px) 0 0;

    ${responsive.md`
      height: 400px;
    `}
  }
`;

const OrderTrackingSMSSignup = styled.div`
  display: flex;
  padding: var(--between-components-modules-spacing-3, 48px) 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--between-components-modules-spacing-15, 24px);
  align-self: stretch;

  h2 {
    color: #142b6f;
    text-align: center;

    ${Font.circular};
    font-style: normal;
    font-weight: 400;

    font-size: 20px;
    line-height: 25px; /* 125% */

    ${responsive.md`
      font-size: 24px;
      line-height: 30px; /* 125% */
      letter-spacing: -0.24px;
    `}
  }

  p {
    color: var(--solid-primary-indigo-blue-100, #142b6f);
    text-align: center;

    ${Font.dutch};
    font-style: normal;
    font-weight: 400;

    font-size: 14px;
    line-height: 20px; /* 142.857% */

    ${responsive.md`
      font-size: 16px;
      line-height: 24px; /* 150% */
    `}
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--between-components-modules-spacing-1, 16px);

    input[type="tel"] {
      color: var(--solid-primary-indigo-blue-100, #142b6f);
      border-radius: var(--between-components-only-spacing-025, 4px);
      border: 1px solid var(--solid-primary-indigo-blue-60, #62719f);
      background: var(--solid-neutral-white, #fff);
      height: 59px;
      padding: var(--between-components-only-spacing-05, 8px)
        var(--between-components-modules-spacing-1, 16px);
      align-self: stretch;

      &:focus {
        border-color: var(--solid-primary-indigo-blue-100, #142b6f);
        outline: none;
      }
    }

    input[type="checkbox"] {
      width: var(--between-components-modules-spacing-15, 24px);
      height: var(--between-components-modules-spacing-15, 24px);
      stroke-width: 1px;
      stroke: var(--solid-primary-indigo-blue-40, #a1aac5);

      &:checked {
        border-radius: 4px;
        background: var(--solid-primary-indigo-blue-100, #142b6f);
      }
    }

    button {
      padding: 12px 24px;
      border-radius: 25px;
      border: 0px;
      text-align: center;
      ${Font.circular};
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      background: var(--solid-primary-indigo-blue-100, #142b6f);
      color: var(--solid-neutral-white, #fff);

      &:hover {
        background: var(--solid-primary-yellow-100, #ffd600);
        color: var(--solid-primary-indigo-blue-100, #142b6f);
      }

      &:focus {
        border: 2px solid var(--solid-primary-indigo-blue-40, #a1aac5);
        color: var(--solid-neutral-white, var(--solid-neutral-white, #fff));
        background: var(--solid-primary-indigo-blue-100, #142b6f);
      }

      &:disabled {
        background: var(--solid-neutral-cool-40, #eaeef0);
        color: var(--solid-primary-indigo-blue-40, #a1aac5);
      }
    }
  }
`;

const MarketingOptIn = styled.div`
  display: flex;
  align-items: center;
  gap: var(--between-components-only-spacing-075, 12px);

  label {
    display: flex;
    align-items: center;
    gap: var(--between-components-only-spacing-075, 12px);
    color: #142b6f;
    margin: 0;

    ${Font.circular};
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 20px; /* 142.857% */
  }
`;

const MarketingTerms = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  position: relative;

  &:hover {
    cursor: pointer;

    .info-tooltip {
      display: block;
    }
  }

  svg {
    display: flex;
    width: 16px;
    height: 16px;
    padding: 1.333px;
    justify-content: center;
    align-items: center;

    &:hover + .info-tooltip {
      display: block;
    }
  }

  .info-tooltip {
    position: absolute;
    display: none;
    z-index: 9999;
    right: 0;
    top: 0;
    padding-top: 32px;
    width: 32px;
    height: 32px;

    &:hover {
      display: block;
    }

    p {
      position: absolute;
      right: -24px;
      width: 315px;
      color: #142b6f;
      ${Font.circular};
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 15px;
      text-align: left;
      background-color: white;
      border: 1px solid #142b6f;
      padding: 12px;
      margin: 0;
      cursor: default;

      &:after {
        content: "";
        position: absolute;
        top: -8.5px;
        background-color: white;
        border: 1px solid rgb(20, 43, 111);
        right: 23px;
        width: 16px;
        height: 16px;
        transform: rotate(45deg);
        z-index: 999;
        border-right: 0;
        border-bottom: 0;
      }

      a {
        text-decoration: underline;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
`;

const SuccessMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  gap: var(--between-components-only-spacing-075, 12px);
  padding: var(--between-components-modules-spacing-2, 32px)
    var(--between-components-modules-spacing-15, 24px);

  ${responsive.md`
    gap: var(--between-components-modules-spacing-15, 24px);
    padding: var(--between-components-modules-spacing-3, 48px) 0px;
  `}

  h2 {
    color: #142b6f;
    text-align: center;

    ${Font.circular};
    font-style: normal;
    font-weight: 300;

    font-size: 20px;
    line-height: 25px; /* 125% */

    ${responsive.md`
      font-size: 24px;
      line-height: 30px; /* 125% */
      letter-spacing: -0.24px;
    `}
  }

  p {
    color: var(--solid-primary-indigo-blue-100, #142b6f);
    text-align: center;

    ${Font.dutch};
    font-style: normal;
    font-weight: 400;

    font-size: 14px;
    line-height: 20px; /* 142.857% */

    ${responsive.md`
      font-size: 16px;
      line-height: 24px; /* 150% */
    `}
  }
`;

const SMSSignup = ({ address }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [marketingOptIn, setMarketingOptIn] = useState(false);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const mapContainer = useRef(null);

  useEffect(() => {
    mapboxgl.accessToken = process.env.GATSBY_MAPBOX_TOKEN;

    const geocode = async () => {
      const formattedAddress = `${address.line1}, ${address.city}, ${address.state}, ${address.postal_code}, ${address.country}`;

      const res = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
          formattedAddress,
        )}.json?access_token=${mapboxgl.accessToken}`,
      );
      const data = await res.json();
      const [longitude, latitude] = data.features[0].center;

      new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [longitude, latitude],
        zoom: 11,
        interactive: false,
      });
    };

    geocode();
  }, []);

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);

    if (value && isValidPhoneNumber(value)) {
      setIsPhoneNumberValid(true);
    } else {
      setIsPhoneNumberValid(false);
    }
  };

  const handleMarketingOptInChange = (e) => {
    setMarketingOptIn(e.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // TODO: Test this with a logged out user
    // TODO: Handle the marketing opt-in checkbox value.
    const currentUser = getStoredUser();
    if (currentUser) {
      metrics.identify(currentUser.id, {
        phone: phoneNumber,
        smsMarketingOptIn: marketingOptIn,
      });
    } else {
      metrics.identify(null, {
        phone: phoneNumber,
        smsMarketingOptIn: marketingOptIn,
      });
    }

    metrics.track("SMS Signup", {
      phone: phoneNumber,
      smsMarketingOptIn: marketingOptIn,
    });

    setShowSuccessMessage(true);
  };

  const locale = navigator.language;
  const defaultCountry = locale.split("-")[1].toUpperCase();

  return (
    <OrderTrackingSection>
      <div className="map" ref={mapContainer}></div>
      {!showSuccessMessage && (
        <OrderTrackingSMSSignup>
          <h2>{intl.t("checkout.confirmation.sms-signup.title")}</h2>
          <p>{intl.t("checkout.confirmation.sms-signup.copy")}</p>
          <form onSubmit={handleSubmit}>
            <Input
              placeholder={intl.t(
                "checkout.confirmation.sms-signup.placeholder",
              )}
              country={defaultCountry}
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              maxLength={15}
            />
            <MarketingOptIn>
              <label>
                <input
                  type="checkbox"
                  value={marketingOptIn}
                  onChange={handleMarketingOptInChange}
                />
                {intl.t("checkout.confirmation.sms-signup.marketingOptIn")}
              </label>
              <MarketingTerms>
                <Icons.Info $color={Color.indigoBlue40} />
                <div className="info-tooltip">
                  <p>
                    By signing up for text, you agree to receive recurring
                    automated promotional and personalized marketing text
                    messages (e.g. cart reminders) at this number from Ritual.
                    Consent is not a condition of any purchase. Reply HELP for
                    help and STOP to cancel. Msg frequency varies. Msg & data
                    rates may apply. View{" "}
                    <a href="/terms-of-service" target="_blank">
                      Terms of Service
                    </a>{" "}
                    and{" "}
                    <a href="/privacy-policy" target="_blank">
                      Privacy Policy
                    </a>
                    .
                  </p>
                </div>
              </MarketingTerms>
            </MarketingOptIn>
            <button type="submit" disabled={!isPhoneNumberValid}>
              {intl.t("checkout.confirmation.sms-signup.button")}
            </button>
          </form>
        </OrderTrackingSMSSignup>
      )}
      {showSuccessMessage && (
        <SuccessMessage>
          <h2>{intl.t("checkout.confirmation.sms-signup.success")}</h2>
          <p>{intl.t("checkout.confirmation.sms-signup.success-copy")}</p>
        </SuccessMessage>
      )}
    </OrderTrackingSection>
  );
};

export default SMSSignup;
