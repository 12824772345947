if (typeof window === "undefined") {
  module.exports = {
    Map: function () {
      return {
        on: function () {},
      };
    },
    Marker: function () {},
  };
} else {
  module.exports = require("../../node_modules/mapbox-gl/dist/mapbox-gl.js");
}
