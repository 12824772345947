import styled from "styled-components";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { getStore } from "../../store/createStore";
import staticDataSelectors, {
  contentfulProductBundlesForSlug,
} from "../../store/staticData/selectors";
import productSelectors from "../../store/product/selectors";
import planSelectors from "../../store/plan/selectors";
import { productOfferForId } from "../../store/product-offer/selectors";
import { Font, responsive } from "../../utils/style";
import { getVariantDescription } from "../cart/CartItem";

const Item = styled.div`
  display: flex;
  align-items: flex-start;
  gap: var(--between-components-modules-spacing-1, 16px);
  align-self: stretch;

  padding-bottom: var(--between-components-modules-spacing-1, 16px);
  border-bottom: 0.5px solid var(--solid-neutral-cool-40, #eaeef0);

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

const ItemImage = styled.div`
  border-radius: 8px;
  overflow: hidden;

  max-width: 64px;

  ${responsive.md`
    max-width: 120px;
  `}

  .gatsby-image-wrapper {
    aspect-ratio: 1 / 1;
  }
`;

const ItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  flex: 1 0 0;
  align-self: stretch;

  ${responsive.md`
    gap: 31px;
  `}
`;

const ItemTitle = styled.div`
  p {
    margin: 0;
  }
`;

const ItemTitleSubhead = styled.p`
  ${Font.dutch};
  font-size: 0.75rem;
  font-weight: 400;
  font-style: italic;
  color: var(
    --solid-primary-indigo-blue-100,
    var(--solid-primary-indigo-blue-100, #142b6f)
  );
  font-family: Dutch801 Rm BT;
  font-weight: 400;
  line-height: 18px;
`;

const ItemTitleName = styled.p`
  ${Font.circular};
  color: var(
    --solid-primary-indigo-blue-100,
    var(--solid-primary-indigo-blue-100, #142b6f)
  );
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const ItemDescription = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex: 1 0 0;
  align-self: stretch;
`;

const ItemVariant = styled.div`
  ${Font.circular};
  color: var(
    --solid-primary-indigo-blue-60,
    var(--solid-primary-indigo-blue-60, #62719f)
  );
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;

  p {
    margin: 0;
  }
`;

const ItemQuantity = styled.div`
  color: var(--solid-primary-indigo-blue-40, #a1aac5);
  text-align: center;
  ${Font.circular};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */

  p {
    margin: 0;
  }
`;

const OrderItem = ({ cartProduct }) => {
  const storeState = getStore().getState();

  const product = productSelectors.productForId(storeState, {
    id: cartProduct?.productId,
  });
  const plan = planSelectors.planForId(storeState, { id: cartProduct?.planId });
  const productOffer = productOfferForId(
    storeState,
    cartProduct?.productOfferId,
  );
  const contentfulProduct = staticDataSelectors.contentfulProductForId(
    storeState,
    { contentfulId: product?.contentfulId },
  );
  const contentfulProductBundle = contentfulProductBundlesForSlug(
    storeState,
    productOffer?.slug,
  );

  if (!contentfulProduct && !contentfulProductBundle) {
    return null;
  }

  const itemContent = {
    id: contentfulProduct?.id || contentfulProductBundle?.id,
    cartImage:
      contentfulProduct?.cartImage || contentfulProductBundle?.shopImages[0],
    subhead: contentfulProduct?.productSubhead,
    name: contentfulProduct?.summary || productOffer.name,
    variantDescription: getVariantDescription(plan, productOffer) || null,
    description:
      (product && product.description) || (productOffer && "Monthly") || null,
  };

  return (
    <Item key={cartProduct.id}>
      <ItemImage>
        <GatsbyImage
          id={`${itemContent.id}-cart-image`}
          key={`${itemContent.id}-cart-image`}
          className="d-block"
          image={getImage(itemContent?.cartImage)}
          loading="eager"
          alt={itemContent?.cartImage?.description || ""}
        />
      </ItemImage>
      <ItemDetails>
        <ItemTitle>
          <ItemTitleSubhead>{itemContent?.subhead}</ItemTitleSubhead>
          <ItemTitleName>{itemContent?.name}</ItemTitleName>
        </ItemTitle>
        <ItemDescription>
          <ItemVariant>
            {itemContent.variantDescription && (
              <p className="item-variant-description">
                {itemContent.variantDescription}
              </p>
            )}
            {itemContent.description && (
              <p className="item-cadence">{itemContent.description}</p>
            )}
          </ItemVariant>
          <ItemQuantity>
            <p>Qty {cartProduct.quantity}</p>
          </ItemQuantity>
        </ItemDescription>
      </ItemDetails>
    </Item>
  );
};

export default OrderItem;
